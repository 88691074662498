import Vue from 'vue';
import Vuex from 'vuex';

import client from '../sdk/client-factory';

Vue.use(Vuex);

/*
Do not redirect user from here (no $router.push). Respond to calling code
with appropriate indicators and let the calling code redirect as needed.
*/

export default new Vuex.Store({
    state: {
        focus: null,
        accountId: null, // account id, set by App.vue for /account routes
        isReady: false, // indicates that we loaded session info from server, so we know if user is authenticated; and if user is authenticated, that we've also loaded user info and account info from server
        serviceInfo: {}, // registration_mode, stripeTokenPublicKey
        brandprofile: null,
        brand: null,
        palette: null,
        // TODO: eventually load the palette from cdn.brandprofile.org; but only when we implement custom hostnames for enterprise-branded Cygnia instance; so for now we just have our own palette here hard-coded
        /*
        We use a default palette with greys to minimize the flicker that
        happens when the actual brand palette is finally loaded; if we
        use vibrant brand colors, the flicker is very noticeable if the
        loaded brand colors are different.
        */
        defaultPalette: {
            text: '#000000', // black
            primary: '#BDBDBD', // grey lighten-1
            primaryText: '#ffffff', // white; text color that should be used when text is over the primary color
            secondary: '#E0E0E0', // grey lighten-2
            accent: '#B0BEC5', // blue-grey lighten-3
            background: '#F5F5F5', // grey lighten-4
        },
        brandNotFoundError: false,
        partnerBrandprofile: null,
        partnerBrand: null,
        partnerPalette: null,
        partnerBrandNotFoundError: false,
        // serviceVersion: {}, // version
        session: { isAuthenticated: false }, // userId, isAuthenticated, notAfter, isCsrfGuardEnabled
        user: {}, // name, email, sessionIdleExpiresMillis
        account: null, // account loaded for /account routes
        interactionMap: {}, // id => interaction ( type: string, next: string, state: object )
        intentMap: null, // intent => intent_params
        nav: { queue: [] }, // queue  with items pushed to it, so whenever we are done with something and want to know where to return to, we pop the last item from the queue and go there; and each item can have a function to determine if it's still valid (and the user should be directed there) or if it should be ignored (remove from the queue and proceed to next item)
        loadingMap: { init: true },
        // noteList: [],
    },
    getters: {
        isLoading(state) {
            return Object.values(state.loadingMap).reduce((acc, item) => acc || item, false);
        },
        brandName(state) {
            return state.brand?.name ?? ''; // 'Loading...';
        },
        primaryColor(state) {
            let result;
            if (Array.isArray(state.palette?.content?.primary) && state.palette.content.primary.length > 0) {
                result = state.palette.content.primary[0].hex;
            } else {
                result = state.defaultPalette.primary;
            }
            // TODO: input validation that palette primary color is a valid hex color value or html color name
            return result;
        },
        primaryTextColor(state) {
            return state.defaultPalette.primaryText;
        },
        accentColor(state) {
            let result;
            if (Array.isArray(state.palette?.content?.accent) && state.palette.content.accent.length > 0) {
                result = state.palette.content.accent[0].hex;
            } else {
                result = state.defaultPalette.accent;
            }
            // TODO: input validation that palette accent color is a valid hex color value or html color name
            return result;
        },
        cardTitleBarTextStyle(state, getters) {
            return `color: ${getters.primaryTextColor}`;
        },
        cardTitleBarStyle(state, getters) {
            return `background-color: ${getters.primaryColor}`;
        },
        primaryButtonStyle(state, getters) {
            return `color: ${getters.primaryTextColor}; background-color: ${getters.primaryColor};`;
        },
        primaryIconStyle(state, getters) {
            return `color: ${getters.primaryColor};`;
        },
    },
    mutations: {
        ready(state) {
            console.log('vuex store: ready');
            state.isReady = true;
        },
        focus(state, value) {
            state.focus = value;
        },
        setServiceInfo(state, serviceInfo) {
            state.serviceInfo = serviceInfo;
        },
        session(state, session) {
            state.session = session;
        },
        user(state, user) {
            state.user = user;
        },
        account(state, account) {
            state.account = account;
        },
        brandprofile(state, brandprofile) {
            // console.trace(`vuex store: set brandprofile to ${brandprofile}`);
            state.brandprofile = brandprofile;
        },
        brand(state, brand) {
            state.brand = brand;
        },
        palette(state, palette) {
            state.palette = palette;
        },
        brandNotFoundError(state, value) {
            state.brandNotFoundError = value;
        },
        partnerBrandprofile(state, brandprofile) {
            // console.trace(`vuex store: set brandprofile to ${brandprofile}`);
            state.partnerBrandprofile = brandprofile;
        },
        partnerBrand(state, brand) {
            state.partnerBrand = brand;
        },
        partnerPalette(state, palette) {
            state.partnerPalette = palette;
        },
        partnerBandNotFoundError(state, value) {
            state.partnerBrandNotFoundError = value;
        },
        // interactionKV is { [interactionId]: interactionRecord }
        setInteraction(state, interactionKV) {
            state.interactionMap = { ...state.interactionMap, ...interactionKV };
        },
        setNav(state, nav) {
            state.nav = nav;
        },
        loading(state, progress) {
            state.loadingMap = { ...state.loadingMap, ...progress };
        },
        // intentKV is { [intent]: intent_params }
        setIntent(state, intentKV) {
            state.intent = { ...state.intentMap, ...intentKV };
        },
        intentParams(state, intentParams) {
            state.intentParams = intentParams;
        },
        // noteList(state, values) {
        //     state.noteList = [...values];
        // },
    },
    actions: {
        // async createAccount({ commit, dispatch, state }, accountInfo) {
        //     commit('loading', { createAccount: true });
        //     const response = await client.user.create(accountInfo);
        //     if (response.isCreated) {
        //         await dispatch('loadSession');
        //         if (state.session.isAuthenticated) {
        //             await dispatch('loadUser');
        //             // await dispatch('loadAccount');
        //         }
        //     }
        //     commit('loading', { createAccount: false });
        //     return response;
        // },
        async logout({ commit }) {
            commit('loading', { logout: true });
            await client.main().authn.logout();
            // https://vuex.vuejs.org/guide/mutations.html#mutations-follow-vue-s-reactivity-rules
            commit('session', { isAuthenticated: false });
            commit('loading', { logout: false });
        },
        // async enableCsrfGuard({ commit, state }) {
        //     const csrfTokenResponse = await client.main().authn.createCsrfToken();
        //     if (csrfTokenResponse.token) {
        //         const csrfGuardToken = csrfTokenResponse.token;
        //         localStorage.setItem('csrfGuardToken', csrfGuardToken);
        //         commit('session', { ...state.session, isCsrfGuardEnabled: true, csrfGuardToken });
        //     }
        // },
        async init({ commit, dispatch, state }, { force = false } = {}) {
            if (state.isReady && !force) {
                console.log('vuex store: init already done');
                return;
            }
            console.log('vuex store: init');
            commit('loading', { init: true });
            try {
                await Promise.all([
                    dispatch('loadServiceInfo'),
                    dispatch('loadSession'),
                ]);
                console.log('vuex store: loaded service info and session');
                /*
                if (state.session.isCsrfGuardEnabled && state.session.csrfGuardToken) {
                    // csrf guard enabled, store the token for use by loginshiedl client
                    localStorage.setItem('csrfGuardToken', state.session.csrfGuardToken);
                } else {
                    // enable csrf guard
                    await dispatch('enableCsrfGuard');
                }
                */
                if (state.session.isAuthenticated) {
                    // load data concurrently
                    await Promise.all([
                        dispatch('loadUser'),
                    ]);
                }
                console.log('vuex store: ready');
                commit('ready');
            } catch (err) {
                console.error('vuex store: init failed');
            }
            commit('loading', { init: false });
        },
        async refresh({ dispatch, state, commit }) {
            console.log('vuex store: refresh');
            // not displaying loading bar because we want this to be transparent
            try {
                await dispatch('loadSession', { progressIndicator: false });
                if (state.session.isAuthenticated) {
                    await dispatch('loadUser', { progressIndicator: false });
                } else {
                    commit('user', {});
                }
            } catch (err) {
                console.error('vuex store: refresh failed');
            }
        },
        async loadServiceInfo({ commit }) {
            commit('loading', { loadServiceInfo: true });
            try {
                const [/* versionInfo, */serviceInfo] = await Promise.all([
                    // client.service.getVersion(),
                    client.main().service.getInfo(),
                ]);
                // commit('setServiceVersion', versionInfo);
                commit('setServiceInfo', serviceInfo);
            } catch (err) {
                console.error('vuex store: failed to load service info');
            }
            commit('loading', { loadServiceInfo: false });
        },
        async loadSession({ commit, dispatch, state }, { progressIndicator = true } = {}) {
            if (progressIndicator) {
                commit('loading', { loadSession: true });
            }
            try {
                const sessionInfo = await client.main().authn.get();
                console.log(`vuex store: session ${JSON.stringify(sessionInfo)}`);
                const now = Date.now();
                const {
                    user_id: userId,
                    authenticated = false,
                    authenticated_duration: authenticatedDuration = null,
                    unlocked = false,
                    unlocked_duration: unlockedDuration = null,
                    // duration = null,
                    refresh_after_duration: refreshAfterDuration = null,
                    etag = {},
                } = sessionInfo;
                let { reloadTimeoutId } = state.session;
                if (authenticated && typeof refreshAfterDuration === 'number' && refreshAfterDuration > 0) {
                    // clear a previous timeout, if it exists
                    if (reloadTimeoutId) {
                        console.log(`vuex store: clearing timeout ${reloadTimeoutId}`);
                        clearTimeout(reloadTimeoutId);
                    }
                    console.log(`vuex store: scheduling session reload for ${refreshAfterDuration} ms`);
                    reloadTimeoutId = setTimeout(() => {
                        console.log('vuex store: reloading session');
                        dispatch('loadSession');
                    }, refreshAfterDuration);
                }
                commit('session', {
                    userId,
                    isAuthenticated: authenticated,
                    isUnlocked: unlocked,
                    authenticatedNotAfter: typeof authenticatedDuration === 'number' && authenticatedDuration > 0 ? now + authenticatedDuration : null,
                    unlockedNotAfter: typeof unlockedDuration === 'number' && unlockedDuration > 0 ? now + unlockedDuration : null,
                    nextRefresh: typeof refreshAfterDuration === 'number' && refreshAfterDuration > 0 ? now + refreshAfterDuration : null,
                    etag,
                    reloadTimeoutId,
                });
            } catch (err) {
                commit('session', { fault: { type: 'read-failed' } });
            }
            commit('loading', { loadSession: false });
        },
        async loadUser({ commit, state }, { progressIndicator = true } = {}) {
            try {
                if (progressIndicator) {
                    commit('loading', { loadUser: true });
                }
                const userInfo = await client.user(state.session.userId).user.get();
                commit('user', userInfo);
            } catch (err) {
                commit('user', { fault: { type: 'read-failed' } });
            } finally {
                commit('loading', { loadUser: false });
            }
        },
        async loadAccount({ commit }, { accountId, progressIndicator = true } = {}) {
            try {
                if (progressIndicator) {
                    commit('loading', { loadAccount: true });
                }
                const accountInfo = await client.account(accountId).currentAccount.get();
                commit('account', accountInfo);
            } catch (err) {
                console.error('failed to load account', err);
                commit('account', null);
            } finally {
                commit('loading', { loadAccount: false });
            }
        },
        // TODO: loadAccountList
        // async editSession({ commit }, sessionInfo) {
        //     commit('loading', { editSession: true });
        //     let isEdited = false;
        //     try {
        //         const newSessionInfo = await client.main().authn.edit(sessionInfo);
        //         commit('session', newSessionInfo);
        //         isEdited = true;
        //     } catch (err) {
        //         console.log('editSession error: %o', err);
        //     }
        //     commit('loading', { editSession: false });
        //     return isEdited;
        // },
        async editCurrentUser({ commit, state }, userInfo) {
            try {
                commit('loading', { editCurrentUser: true });
                const { isEdited } = await client.user(state.session.userId).user.edit(userInfo);
                // https://vuex.vuejs.org/guide/mutations.html#mutations-follow-vue-s-reactivity-rules
                const newUserInfo = { ...state.user, info: { ...state.user.info, ...userInfo } };
                commit('user', newUserInfo);
                return isEdited;
            } catch (err) {
                console.error('editCurrentUser error', err);
                return false;
            } finally {
                commit('loading', { editCurrentUser: false });
            }
        },
        async createInteraction({ commit }, interaction) {
            commit('loading', { createInteraction: true });
            console.log('store: createInteraction %o', interaction);
            const response = await client.main().interaction.create(interaction);
            if (response.id) {
                commit('setInteraction', { [response.id]: response });
            }
            commit('loading', { createInteraction: false });
            return response;
        },
        async editInteraction({ commit }, { interactionId, message }) {
            commit('loading', { editInteraction: true });
            console.log('store: editInteraction %s', interactionId);
            const response = await client.main().interaction.edit(interactionId, message);
            if (response.id) {
                commit('setInteraction', { [response.id]: response });
            }
            commit('loading', { editInteraction: false });
            return response;
        },
        async loadInteraction({ commit }, interactionId) {
            try {
                commit('loading', { loadInteraction: true });
                const response = await client.main().interaction.get(interactionId);
                console.log(`loadInteraction response: ${JSON.stringify(response)}`); // { type, state, intent, intent_params }
                // const {
                //     type,
                //     state,
                //     intent, intent_params: intentParams } = response; // type and state may still be available but we only need the intent here to redirect the user to the next step
                // const { intent, intent_params: intentParams } = response;
                // console.log(`interaction.vue: loadInteraction: interaction type: ${type} state ${JSON.stringify(state)}`);
                // console.log(`interaction.vue: loadInteraction: interaction intent: ${intent} params ${JSON.stringify(intentParams)}`);
                // this.intent = intent;
                // this.intentParams = intentParams;
                commit('setInteraction', { [interactionId]: response });
                return response;
            } catch (err) {
                console.error('failed to activate token', err);
                return null;
            } finally {
                commit('loading', { loadInteraction: false });
            }
        },
        async loadBrand({ commit, state }) {
            try {
                commit('loading', { loadBrand: true });
                const request = { alias: state.brandprofile };
                const response = await client.brandprofile().brand.get(request);
                commit('brand', response);
                if (state.brandprofile === null || state.brandprofile !== response.alias) {
                    console.log(`store: loadBrand: brandprofile was ${state.brandprofile} but response from server shows ${response.alias}, updating`);
                    commit('brandprofile', response.alias);
                }
            } catch (err) {
                console.error('loadBrand failed', err);
                commit('brand', null);
                commit('brandNotFoundError', true);
            } finally {
                commit('loading', { loadBrand: false });
            }
        },
        async loadPalette({ commit, state }, { mode = 'light' }) {
            try {
                commit('loading', { loadPalette: true });
                const request = { alias: state.brandprofile };
                const match = {
                    ...request,
                    mode,
                };
                const response = await client.brandprofile().palette.get(match);
                commit('palette', response);
            } catch (err) {
                console.error('loadPalette failed', err);
                commit('palette', null);
            } finally {
                commit('loading', { loadPalette: false });
            }
        },
        async loadPartnerBrand({ commit, state }) {
            try {
                commit('loading', { loadBrand: true });
                const request = { alias: state.partnerBrandprofile };
                const response = await client.brandprofile().brand.get(request);
                commit('partnerBrand', response);
                if (state.partner_brandprofile === null || state.partner_brandprofile !== response.alias) {
                    console.log(`store: loadBrand: brandprofile was ${state.partner_brandprofile} but response from server shows ${response.alias}, updating`);
                    commit('partnerBrandprofile', response.alias);
                }
            } catch (err) {
                console.error('loadBrand failed', err);
                commit('partnerBrand', null);
                commit('partnerBrandNotFoundError', true);
            } finally {
                commit('loading', { loadBrand: false });
            }
        },
        async loadPartnerPalette({ commit, state }, { mode = 'light' }) {
            try {
                commit('loading', { loadPalette: true });
                const request = { alias: state.partnerBrandprofile };
                const match = {
                    ...request,
                    mode,
                };
                const response = await client.brandprofile().palette.get(match);
                commit('parterPalette', response);
            } catch (err) {
                console.error('loadPalette failed', err);
                commit('partnerPalette', null);
            } finally {
                commit('loading', { loadPalette: false });
            }
        },
        queueNavItem({ commit, state }, item) {
            const queue = [...state.nav.queue];
            queue.push({ path: item.path, query: item.query || {}, hash: item.hash || '' });
            commit('setNav', { queue });
        },
        nextNavItem({ commit, state }) {
            try {
                const queue = [...state.nav.queue];
                if (queue.length > 0) {
                    const next = queue.splice(queue.length - 1, 1);
                    commit('setNav', { queue });
                    return next[0];
                }
                if (state.session.isAuthenticated) {
                    return { path: '/dashboard' };
                }
                return { path: '/' };
            } catch (err) {
                console.log('forward error: %o', err);
                return { path: '/' };
            }
        },
    },
});
