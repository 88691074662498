import AccountClient from './client/account';
import BrandClient from './client/brandprofile';
import MainClient from './client/main';
import UserClient from './client/user';

/* eslint-disable dot-notation */

const clientMap = {}; // org id -> client configured for that organization
const client = {
    account(id) {
        const idx = `account_${id}`;
        if (!clientMap[idx]) {
            clientMap[idx] = new AccountClient({ endpoint: '/', serviceEndpoint: '/api/browser', accountId: id });
        }
        return clientMap[idx];
    },
    brandprofile() {
        if (!clientMap['brandprofile']) {
            clientMap['brandprofile'] = new BrandClient({ endpoint: '/', serviceEndpoint: '/api/brand' });
        }
        return clientMap['brandprofile'];
    },
    main() {
        if (!clientMap['main']) {
            clientMap['main'] = new MainClient({ endpoint: '/', serviceEndpoint: '/api/browser' });
        }
        return clientMap['main'];
    },
    user(id) {
        const idx = `user_${id}`;
        if (!clientMap[idx]) {
            clientMap[idx] = new UserClient({ endpoint: '/', serviceEndpoint: '/api/browser', userId: id });
        }
        return clientMap[idx];
    },
};

export default client;
