import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

/**
 * The 'layout' meta parameter indicates the page layout to use for that view; see plugins/layouts.js for options.
 * Authentication is required by default for all views. The `isPublic` meta parameter indicates that a view
 * is public and does not require authentication to display.
 */
const router = new Router({
    mode: 'history',
    base: process.env.VUE_APP_WEBSITE_PATH || '/',
    routes: [
        {
            path: '/',
            name: 'front',
            meta: { layout: 'main-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "front" */ '../views/Front.vue'),
        },
        {
            path: '/prompt',
            name: 'prompt',
            meta: { layout: 'brand-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "prompt" */ '../views/PromptActivity.vue'),
        },
        {
            path: '/verify/email',
            name: 'verify-email',
            meta: { layout: 'brand-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "verify-email" */ '../views/verify/VerifyEmail.vue'),
        },
        {
            /* NOTE: this path is configured on the server and other websites; do not change it without coordination */
            path: '/signup',
            name: 'signup',
            meta: { layout: 'main-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "signup" */ '../views/SignupActivity.vue'),
        },
        {
            /* NOTE: this path is configured on the server and other websites; do not change it without coordination */
            path: '/login',
            name: 'login',
            meta: { layout: 'main-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "login" */ '../views/LoginActivity.vue'),
        },
        {
            path: '/logout',
            name: 'logout',
            meta: { layout: 'main-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "logout" */ '../views/LogoutActivity.vue'),
        },
        {
            path: '/interaction',
            name: 'interaction',
            meta: { layout: 'main-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "interaction" */ '../views/InteractionForward.vue'),
        },
        {
            path: '/intent',
            name: 'intent',
            meta: { layout: 'main-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "intent" */ '../views/IntentForward.vue'),
        },
        {
            path: '/session',
            name: 'session',
            meta: { layout: 'main-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "session" */ '../views/CheckSessionActivity.vue'),
        },
        {
            path: '/dashboard',
            name: 'user-dashboard',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "dashboard" */ '../views/user/Dashboard.vue'),
        },
        {
            path: '/profile',
            name: 'profile',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "profile" */ '../views/profile/Index.vue'),
        },
        {
            path: '/profile/settings',
            name: 'profile-settings',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "profile-settings" */ '../views/profile/ProfileSettings.vue'),
        },
        {
            path: '/profile/setup',
            name: 'profile-setup',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "profile-setup" */ '../views/profile/Setup.vue'),
        },
        {
            path: '/profile/authn/loginfront',
            name: 'profile-settings-authn-loginfront',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "profile-settings-authn-loginfront" */ '../views/profile/authn/LoginFront.vue'),
        },
        {
            path: '/profile/authz',
            name: 'profile-settings-authz',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "profile-settings-authz" */ '../views/profile/Authz.vue'),
        },
        {
            path: '/profile/session',
            name: 'profile-settings-session',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "profile-settings-session" */ '../views/profile/Session.vue'),
        },
        {
            path: '/profile/add-email',
            name: 'profile-add-email',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "profile-add-email" */ '../views/profile/AddEmail.vue'),
        },
        {
            path: '/profile/delete',
            name: 'profile-delete',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "profile-delete" */ '../views/profile/Delete.vue'),
        },
        {
            path: '/service',
            name: 'service-admin',
            meta: { layout: 'admin-layout' },
            component: () => import(/* webpackChunkName: "service-admin" */ '../views/service/Index.vue'),
        },
        {
            path: '/service/setup',
            name: 'service-admin-setup',
            meta: { layout: 'admin-layout' },
            component: () => import(/* webpackChunkName: "service-admin-setup" */ '../views/service/Setup.vue'),
        },
        {
            path: '/service/settings',
            name: 'service-admin-settings',
            meta: { layout: 'admin-layout' },
            component: () => import(/* webpackChunkName: "service-admin-settings" */ '../views/service/Settings.vue'),
        },
        // {
        //     path: '/service/c2',
        //     name: 'service-admin-c2',
        //     meta: { layout: 'admin-layout' },
        //     component: () => import(/* webpackChunkName: "service-admin-c2" */ '../views/service/C2.vue'),
        // },
        {
            path: '/user/account-list',
            name: 'user-account-list',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "user-account-list" */ '../views/user/AccountList.vue'),
        },
        {
            path: '/user/create/account',
            name: 'user-create-account',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "user-create-account" */ '../views/user/CreateAccount.vue'),
        },
        {
            path: '/search',
            name: 'search',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "search" */ '../views/SearchActivity.vue'),
        },
        {
            path: '/account/:accountId',
            name: 'account-dashboard',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "account-dashboard" */ '../views/account/Dashboard.vue'),
        },
        {
            path: '/account/:accountId/setup',
            name: 'account-setup',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "account-setup" */ '../views/account/Setup.vue'),
        },
        {
            path: '/account/:accountId/delete',
            name: 'account-delete',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "account-delete" */ '../views/account/Delete.vue'),
        },
        {
            path: '/webauthz/grant',
            name: 'webauthz-grant',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "webauthz-grant" */ '../views/webauthz/Grant.vue'),
        },
        {
            path: '/webauthz/prompt',
            name: 'webauthz-prompt',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "webauthz-prompt" */ '../views/webauthz/Prompt.vue'),
        },
//////////////////////////
/////////
//////////////////////////
/////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////
//////////
/////////////////
        {
            path: '/:catchAll(.*)',
            name: 'not-found',
            meta: { layout: 'main-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "not-found" */ '../views/NotFound.vue'),
        },
    ],
});

router.beforeEach((to, from, next) => {
    window.scrollTo(0, 0);
    next();
});

export default router;
