<template>
    <v-navigation-drawer permanent app clipped>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            Service Admin
          </v-list-item-title>
          <!-- <v-list-item-subtitle>
            subtext
          </v-list-item-subtitle> -->
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense nav>
          <v-list-item :to="{ name: 'service-admin-setup' }">
              <v-list-item-content>
                  <v-list-item-title>Setup</v-list-item-title> <!-- NOTE: currently this is the same as the settings page, BUT this is intended to be a page that does a bunch of checks to inform the admin if the service is fully set up and ready to go, or if some required configuration is missing or something isn't working, like no database access, etc.  so it's different than just letting the admin edit settings, it's about checking to see if things are ready -->
              </v-list-item-content>
          </v-list-item>
          <v-list-item :to="{ name: 'service-admin-settings' }">
              <v-list-item-content>
                  <v-list-item-title>Settings</v-list-item-title>
              </v-list-item-content>
          </v-list-item>
          <!-- <v-list-item :to="{ name: 'service-admin-c2' }">
              <v-list-item-content>
                  <v-list-item-title>C2</v-list-item-title>
              </v-list-item-content>
          </v-list-item> -->
          <v-list-item :to="{ name: 'service-admin-dns' }">
              <v-list-item-content>
                  <v-list-item-title>DNS</v-list-item-title>
              </v-list-item-content>
          </v-list-item>

          <v-list-group>
            <template #activator>
              <v-list-item-title>Dynamic DNS</v-list-item-title>
            </template>
            <v-list-item :to="{ name: 'service-admin-dynamic-shared-domain' }">
                <v-list-item-content>
                    <v-list-item-title>Shared Domains</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
          </v-list-group>
      </v-list>

      <v-divider></v-divider>

      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            Customers
          </v-list-item-title>
          <!-- <v-list-item-subtitle>
            subtext
          </v-list-item-subtitle> -->
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense nav>
          <!-- <v-list-item :to="{ name: 'service-admin-c2' }">
              <v-list-item-content>
                  <v-list-item-title>C2</v-list-item-title>
              </v-list-item-content>
          </v-list-item> -->
          <!-- <v-list-item :to="{ name: 'service-admin-accounts' }">
              <v-list-item-content>
                  <v-list-item-title>Accounts</v-list-item-title>
              </v-list-item-content>
          </v-list-item> -->
          <!-- <v-list-item :to="{ name: 'service-admin-domains' }">
              <v-list-item-content>
                  <v-list-item-title>Domains</v-list-item-title>
              </v-list-item-content>
          </v-list-item> -->
      </v-list>

    </v-navigation-drawer>
</template>

<script>
export default {
    // components: {
    //     Navbar,
    //     Footer,
    //     NavigationDrawer,
    // },
};
</script>
