/*!
Copyright (C) 2020 Cryptium Corporation. All rights reserved.
*/
/* eslint-disable no-console, class-methods-use-this, max-classes-per-file */

const ajax = require('axios');

async function getJson(path, query = null, { requestHeaders = {} } = {}) {
    const response = await ajax.get(path, {
        headers: {
            Accept: 'application/json',
            ...requestHeaders,
        },
        params: query,
    });
    return response.data;
}

async function postJsonAcceptJson(path, request, query = null, { requestHeaders = {} } = {}) {
    const response = await ajax.post(path, request ? JSON.stringify(request) : undefined, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ...requestHeaders,
        },
        params: query,
    });
    return response.data;
}

class User {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/user/${context.userId}`;
        this.requestHeaders = context.requestHeaders;
    }

    async check(request) {
        return postJsonAcceptJson(`${this.url}/check`, request, null, { requestHeaders: this.requestHeaders });
    }

    async get() {
        return getJson(`${this.url}/state`, null, { requestHeaders: this.requestHeaders });
    }

    async edit(request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/edit`, request, null, { requestHeaders: this.requestHeaders });
    }

    async delete() {
        return postJsonAcceptJson(`${this.url}/delete`, null, null, { requestHeaders: this.requestHeaders });
    }

    async search(request) {
        return getJson(`${this.url}/search`, request, { requestHeaders: this.requestHeaders });
    }

    async getAccountList(request) {
        return getJson(`${this.url}/search/account`, request, { requestHeaders: this.requestHeaders });
    }

    async createAccount(request) {
        return postJsonAcceptJson(`${this.url}/create/account`, request, null, { requestHeaders: this.requestHeaders });
    }

    // async create(request) {
    //     return postJsonAcceptJson(`${this.url}/user/create`, request, null, { requestHeaders: this.requestHeaders });
    // }

    // // TODO: move to unicorn springs
    // async invite(request) {
    //     return postJsonAcceptJson(`${this.url}/user/invite`, request, null, { requestHeaders: this.requestHeaders });
    // }

    // // request like { email (string), agreeToTerms (boolean) }
    // // TODO: move to unicorn springs
    // async activate(request) {
    //     return postJsonAcceptJson(`${this.url}/user/activate`, request, null, { requestHeaders: this.requestHeaders });
    // }

    // // TODO: move to unicorn springs
    // async list(request) {
    //     return getJson(`${this.url}/search/user`, request, { requestHeaders: this.requestHeaders });
    // }

    // async get(id) {
    //     return getJson(`${this.url}/user`, { id }, { requestHeaders: this.requestHeaders });
    // }

    // // TODO: move to unicorn springs
    // async edit(id, request) {
    //     // NOTE: you only need to specify the attributes that should be changed
    //     return postJsonAcceptJson(`${this.url}/user/edit`, request, { id }, { requestHeaders: this.requestHeaders });
    // }

    // // TODO: move to unicorn springs
    // async delete({ id }) {
    //     return postJsonAcceptJson(`${this.url}/user/delete`, null, { id }, { requestHeaders: this.requestHeaders });
    // }
}

/**
 * Query for volumes for which the user has read or write permission.
 */
/*
class LinkUserVolume {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/user/${context.userId}`;
        this.requestHeaders = context.requestHeaders;
    }

    async search(query) {
        return getJson(`${this.url}/search/link-user-volume`, query, { requestHeaders: this.requestHeaders });
    }
}
*/

/**
 * This is a resource class to manage email addresses in user's profile.
 * There is no 'create' API because to add an email it needs to be verified
 * first, so adding an email starts with the verification API.
 */
class Email {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/user/${context.userId}`;
        this.requestHeaders = context.requestHeaders;
    }

    async delete(query) {
        return postJsonAcceptJson(`${this.url}/delete/email`, null, query, { requestHeaders: this.requestHeaders });
    }

    async startVerifyEmail(request) {
        return postJsonAcceptJson(`${this.url}/verify/email/start`, request, null, { requestHeaders: this.requestHeaders });
    }

    /* not needed because user arrives to 'resume interaction' with the token
    async checkVerifyEmail(request) {
        return postJsonAcceptJson(`${this.url}/verify/email/token`, request, null, { requestHeaders: this.requestHeaders });
    }
    */

    /**
     * Search requested and verified email addresses associated to this profile
     */
    async search(query) {
        return getJson(`${this.url}/search/email`, query, { requestHeaders: this.requestHeaders });
    }
}

class BrowserClient {
    constructor(context = {}) {
        // this.linkUserVolume = new LinkUserVolume(context);
        this.user = new User(context);
        this.email = new Email(context);
    }
}

export default BrowserClient;
export {
    User,
    Email,
};
