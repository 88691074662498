<template>
  <v-app>
    <Navbar/>
    <AdminNavigationDrawer v-if="isPermitServiceAdmin"/>
    <v-main style="background-color: #efefef; height: 100%;" class="ma-0 print">
      <v-container class="ma-0 pa-0" fluid style="height: 100%;">
        <v-row no-gutters class="ma-0 pa-0" justify="center" style="height: 100%;">
        <v-col cols="12" class="ma-0 pa-0" style="height: 100%;">
          <slot/>
        </v-col>
        </v-row>
      </v-container>
    </v-main>
    <Footer/>
  </v-app>
</template>

<script>
import { mapState } from 'vuex';
import Navbar from '@/components/Navbar.vue';
import Footer from '@/components/Footer.vue';
import AdminNavigationDrawer from '@/components/service-dashboard/AdminNavigationDrawer.vue';

export default {
    name: 'AdminLayout',
    components: {
        Navbar,
        Footer,
        AdminNavigationDrawer,
    },
    computed: {
        ...mapState({
            isAuthenticated: (state) => state.session.isAuthenticated,
            user: (state) => state.user,
        }),
        isPermitServiceAdmin() {
            return this.isAuthenticated && Array.isArray(this.user?.permit?.role) && this.user.permit.role.includes('service-admin');
        },
    },
};
</script>

<style lang="css">
  /* * {
    font-family: 'NunitoSans', sans-serif
  } */
@media print {
  /* removes padding corresponding to navbar and footer when printing document */
  .print {
    padding: 0 !important;
  }
}
</style>
